import React from 'react'
import classNames from 'classnames'
import translations from '../../data/about'
import useLanguage from '../../hooks/useLanguage'
import Main from '../../layouts/Main'
import styles from './AboutPage.module.scss'
import IconBack from '../../assets/images/svg/Icon_arrow_left'

const AboutPage = ({ history }) => {
  const l = useLanguage()
  const content = translations.pageContent

  return (
    <Main>
      <div className={classNames('container', styles.container)}>
        <div className='row'>
          <div className='col'>
            <h2 className={classNames('pb-3 mb-2', styles.headerText)}>
              {l(translations.hero.title)}
            </h2>
            <div
              className='text-left'
              dangerouslySetInnerHTML={{ __html: l(translations.hero.text) }}
            />
          </div>
        </div>
        <div className='row'>
          {content.map(item => {
            return (
              <div className='col-12'>
                <h3 id={item.id} className='hdln--medium hdln--grey pb-2 pt-4'>
                  {l(item.title)}
                </h3>
                <div
                  className={styles.textStyle}
                  dangerouslySetInnerHTML={{ __html: l(item.text) }}
                />
              </div>
            )
          })}
        </div>
        <div className='px-2 px-md-0 pt-4 pb-6 row d-flex justify-content-center'>
          <div className='col-md-6 d-flex justify-content-around'>
            <div
              className='button button--back d-flex justify-content-center align-items-center'
              onClick={history.goBack}
            >
              <IconBack fill='#FFFFFF' />
              <span className='button__text pl-3'>
                {l(translations.buttonBack)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default AboutPage
