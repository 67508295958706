import React from 'react'
import classNames from 'classnames'
import styles from './Cars.module.scss'
import Car from '../../assets/images/car.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

const Cars = ({ totalCars }) => {
  const maxCarCount = 15
  const showMaxCars = totalCars > maxCarCount
  const numberOfCarsToShow = showMaxCars ? maxCarCount - 1 : totalCars
  const includeHalf = numberOfCarsToShow % 1 !== 0

  const parentStyles = classNames('d-flex flex-wrap', styles.carContainer)
  return (
    <div className={parentStyles}>
      {Array(Math.floor(numberOfCarsToShow))
        .fill()
        .map((value, index) => (
          <div key={index} className={styles.wrapper}>
            <div
              className={classNames('mx-2 mx-md-3 my-2', styles.carImg)}
              style={{ backgroundImage: `url(${Car})` }}
            />
          </div>
        ))}
      {includeHalf && !showMaxCars && (
        <div className={classNames(styles.wrapperHalf, styles.wrapper)}>
          <div
            className={classNames('mx-2 mx-md-3 my-2', styles.carImg)}
            style={{ backgroundImage: `url(${Car})` }}
          />
        </div>
      )}
      {showMaxCars && (
        <div
          className={classNames(
            styles.wrapper,
            styles.plusIcon,
            'justify-content-center align-items-center ml-2 ml-md-3 '
          )}
        >
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
    </div>
  )
}

export default Cars
