import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/svg/Logo_kolefnis'

const LinkedLogo = () => {
  return (
      <Link
        to='/'>
        <Logo />
      </Link>
  )
}

export default LinkedLogo
