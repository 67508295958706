import React from 'react'

/* eslint max-len: ['error', { 'code': 15000 }] */
const SvgComponent = props => (
<svg width="18px" height="4px" viewBox="0 0 18 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Kolefni-desktop-hönnun" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="spurning-4-+--" transform="translate(-671.000000, -546.000000)" fill="#58585B" fillRule="nonzero">
            <g id="Group-7" transform="translate(641.000000, 518.000000)">
                <g id="icon-minus" transform="translate(30.000000, 27.903320)">
                    <path d="M16.5,0.5 C17.3284271,0.5 18,1.17157288 18,2 C18,2.82842712 17.3284271,3.5 16.5,3.5 L10.5,3.5 L7.5,3.5 L1.5,3.5 C0.671572875,3.5 0,2.82842712 0,2 C0,1.17157288 0.671572875,0.5 1.5,0.5 L7.5,0.5 L10.5,0.5 L16.5,0.5 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default SvgComponent