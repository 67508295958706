import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import translations from '../../data/results'
import useLanguage from '../../hooks/useLanguage'

const Chart = () => {
  const l = useLanguage()

  const options = {
    chart: {
      styledMode: true,
      type: 'area',
      borderRadius: 15,
      borderWidth: 10
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    xAxis: {
      categories: [
        '2015',
        '2016',
        '2017',
        '2018',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023',
        '2024',
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030',
        '2031',
        '2032',
        '2033',
        '2034',
        '2035',
        '2036',
        '2037',
        '2038',
        '2039',
        '2040'
      ]
    },
    tooltip: {
      shared: true,
      color: '#000000',
      backgroundColor: '#0CC0D8',
      borderColor: '#FFFFFF',
      formatter: function () {
        return this.y + ' ' + l(translations.tooltipResultUnit)
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.1
      }
    },
    series: [
      {
        name: '',
        data: [
          4.6,
          4.46,
          4.32,
          4.18,
          4.04,
          3.9,
          3.76,
          3.62,
          3.48,
          3.34,
          3.2,
          3.06,
          2.92,
          2.78,
          2.64,
          2.5,
          2.39,
          2.28,
          2.17,
          2.06,
          1.95,
          1.84,
          1.73,
          1.62,
          1.51,
          1.4
        ],
        pointInterval: 1
      }
    ]
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default Chart
