import React from 'react'
import Main from '../../layouts/Main'
import FrontPage from '../../components/FrontPage'

const Homepage = ({ location, history }) => {
  return (
    <Main>
      <FrontPage location={location} history={history} />
    </Main>
  )
}

export default Homepage
