import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'
import useShareLink from '../../hooks/shareLink'
import share from '../../assets/images/svg/share.svg'

const ChromeShare = params => {
  const { language } = useContext(LanguageContext)
  const link = useShareLink(params)

  // dont display share button if we cant use it
  const canUseShare = !!navigator.share
  if (!canUseShare) {
    return null
  }

  let text
  if (language === 'is') {
    text = 'Reiknaðu þitt kolefnisspor'
  } else {
    text = 'Calculate your carbon footprint'
  }

  const openShare = () => {
    navigator.share({
      title: 'Kolefnisreiknir',
      url: link,
      text
    })
  }
  return <img alt='Share via device' src={share} onClick={openShare} />
}

export default ChromeShare
