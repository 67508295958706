import React, { useContext } from 'react'
import translations from '../../data/frontpage'
import useLanguage from '../../hooks/useLanguage'
import { LanguageContext } from '../../contexts/LanguageContext'
import styles from './FrontPageStats.module.scss'
import Stats from '../Stats/Stats'
import classNames from 'classnames'

const FrontPageStats = ({ items }) => {
  const l = useLanguage()
  const { language } = useContext(LanguageContext)

  return (
    <section className={classNames(styles.container)}>
      <div className='container'>
        <div className='row d-flex flex-lg-row-reverse flex-column py-4 align-items-center'>
          <div className='col-lg-6 col-md-12'>
            <Stats
              isFrontpage
              categories={translations.stats.graph.categories}
              stats={translations.stats.graph.stats}
              currLanguage={language}
            />
          </div>
          <div className='col-lg-6 col-md-12 pt-md-6 pt-3 pb-md-6 pb-3'>
            <h2 className={styles.headerText}>{l(translations.stats.title)}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: l(translations.stats.text) }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FrontPageStats
