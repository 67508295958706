import React, { useState, useContext } from 'react'
import styles from './SuggestionTabs.module.scss'
import classNames from 'classnames'
import Stats from '../../components/Stats'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import translations from '../../data/results'
import { LanguageContext } from '../../contexts/LanguageContext'
import { suggestions } from '../../data/suggestions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import useLanguage from '../../hooks/useLanguage'

const SuggestionTabs = ({ pageKey, history }) => {
  const suggestionsKeys = Object.keys(suggestions)
  const pageKeyIndex = suggestionsKeys.indexOf(pageKey)
  const tabsEndIndex = suggestionsKeys.length - 1
  const [tabIndex, setTabIndex] = useState(~pageKeyIndex ? pageKeyIndex : 0)

  const l = useLanguage()
  const { language } = useContext(LanguageContext)

  const handleUpdateTabIndex = tabIndex => {
    setTabIndex(tabIndex)
    history.replace(`/suggestions/${suggestionsKeys[tabIndex]}`)
  }

  return (
    <section
      id='suggestions'
      className={classNames(styles.container)}
    >
      <div className='row position-relative'>
        <button
          className={classNames('col-1', styles.arrowButtons)}
          onClick={() =>
            handleUpdateTabIndex(~(tabIndex - 1) ? tabIndex - 1 : tabsEndIndex)
          }
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <div className='col'>
          <Tabs selectedIndex={tabIndex} onSelect={handleUpdateTabIndex}>
            <TabList className='d-flex justify-content-center'>
              {Object.values(suggestions).map((suggestion, index) => {
                return (
                  <Tab key={index}>
                    <div className='text-center'>
                      <span className={styles.linkText}>
                        {l(suggestion.categoryTitle)}
                      </span>
                    </div>
                  </Tab>
                )
              })}
            </TabList>
            <div className='row d-flex justify-content-center'>
              <div className='col-md-8 col-9 pt-3'>
                {Object.values(suggestions).map((suggestion, index) => {
                  return (
                    <TabPanel key={index}>
                      <div className='row'>
                        <div
                          className={classNames(
                            'col-12 col-lg-10',
                            styles.linkText
                          )}
                          dangerouslySetInnerHTML={{
                            __html: l(suggestion.html)
                          }}
                        />
                        <div className='col-lg-2 d-none d-lg-block'>
                          <Stats
                            categories={translations.categories}
                            stats={translations.stats}
                            currLanguage={language}
                            activeCategory={pageKey}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  )
                })}
              </div>
            </div>
          </Tabs>
        </div>
        <button
          className={classNames(
            'col-1 offset-10 offset-md-11',
            styles.arrowButtons
          )}
          onClick={() =>
            handleUpdateTabIndex(tabIndex + 1 > tabsEndIndex ? 0 : tabIndex + 1)
          }
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </section>
  )
}

export default SuggestionTabs
