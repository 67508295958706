import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'
import useShareLink from '../../hooks/shareLink'
import twitterIcon from '../../assets/images/svg/twitterIcon.svg'

const TwitterShare = params => {
  const { language } = useContext(LanguageContext)
  const link = useShareLink(params)
  const endpoint = 'https://twitter.com/intent/tweet'
  const hashtag = '#kolefnisreiknir'

  let text

  if (language === 'is') {
    text = `Ég reiknaði mitt kolefnisspor á ${link}. Nú er komið að þér! ${escape(hashtag)}`
  } else {
    text = `Calculate your carbon footprint ${link} ${escape(hashtag)}`
  }
  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={`${endpoint}?ref_src=twsrc%5Etfw&text=${text}`}
    >
      <img alt='Share to twitter' src={twitterIcon} />
    </a>
  )
}

export default TwitterShare
