import trips from './trips.json'
import food from './food.json'
import home from './home.json'
import consumption from './consumption.json'

export default [
  trips,
  food,
  home,
  consumption
]