import React from 'react'
import useShareLink from '../../hooks/shareLink'
import facebookIcon from '../../assets/images/svg/facebookIcon.svg'

const faShare = (endpoint, link) => {
  const top = (window.innerHeight / 2) - (390 / 2)
  const left = (window.innerWidth / 2) - (660 / 2)

  const windowObjectReference = window.open(
    `${endpoint}?u=${encodeURIComponent(link)}&hashtag=%23kolefnisreiknir`,
    'Facebook share',
    `menubar=1,resizable=1,width=660px,height=390px,top=${top}`+`,left=${left}`
  )
}

const FacebookShare = params => {
  const link = useShareLink(params)
  const endpoint = 'https://www.facebook.com/sharer/sharer.php'

  return (
    <a
      onClick={e => {
        e.preventDefault()
        faShare(endpoint, link)}
      }
      target='_blank'
      rel='noopener noreferrer'
      href={`${endpoint}?u=${encodeURIComponent(link)}&hashtag=%23kolefnisreiknir`}
    >
      <img alt='Share to facebook' src={facebookIcon} />
    </a>
  )
}

export default FacebookShare
