import React from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'
import transitions from '../../data/steps'
import useLanguage from '../../hooks/useLanguage'
import 'react-step-progress-bar/styles.css'
import styles from './Steps.module.scss'
import CheckMark from '../../assets/images/svg/Icon_check'
import classNames from 'classnames'

class Steps extends React.Component {
  isCurr (percent, position, percentagePerCategory) {
    return percent === position || percent === percentagePerCategory / 2 + position
  }

  render () {
    const {
      questions,
      categoryIndex,
      stepIndex,
      language: l
    } = this.props
    const grayLight = '#979797'
    const green = '#6ca234'

    // calculate at what percentage the current step and category is of 100%
    const stepCount = questions[categoryIndex].question_steps.length
    const percentagePerCategory = 100/questions.length
    const percentagePerStep = percentagePerCategory/stepCount
    const percent = percentagePerCategory * categoryIndex + percentagePerStep * stepIndex

    // we need to add a final step in the end
    const processedSteps = [
      ...questions,
      {
        title: transitions.finalStepTitle
      }
    ]

    return (
      <ProgressBar
        // percent will move the progressbar
        percent={percent}
        unfilledBackground={grayLight}
        filledBackground={green}
        width='740px'
        height='1.5px'
      >
        {
          processedSteps.map((step, i) =>
            <Step key={i}>
              {({ accomplished }) => (
                <div className={styles.stepContainer}>
                  <p className={classNames(styles.inactiveTitle, { [styles.activeTitle]: accomplished })}>{ l(step.title) }</p>
                  <div className={classNames(styles.outerEmpty, { [styles.outerCircle]: this.isCurr(percent, percentagePerCategory*i, percentagePerCategory) })}>
                    <p className={classNames(styles.innerCircle, {
                      [styles.activeCircle]: accomplished
                    })}
                    >
                      { percentagePerCategory*i <= percent - 25 ? <CheckMark /> : i + 1 }
                    </p>
                  </div>
                </div>
              )}
            </Step>
          )
        }
      </ProgressBar>
    )
  }
}

export default props => {
  const l = useLanguage()
  return <Steps language={l} {...props} />
}
