import React from 'react'

/* eslint max-len: ['error', { 'code': 15000 }] */
const SvgComponent = props => (
  <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
    <title>A7154456-747A-4E61-A6E9-8933117775D4</title>
    <desc>Created with sketchtool.</desc>
    <g id='Kolefni-desktop-hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='spurning-1-radio-btn' transform='translate(-1270.000000, -83.000000)' fill='#58585B' fillRule='nonzero'>
        <g id='icon-globe' transform='translate(1270.000000, 83.000000)'>
          <path d='M2.06,11 C2.43383482,13.9677584 4.43206732,16.4790506 7.24,17.51 C6.44503231,15.4310579 6.03174345,13.2257215 6.02,11 L2.06,11 Z M2.06,9 L6.02,9 C6.03174345,6.77427853 6.44503231,4.56894212 7.24,2.49 C4.43206732,3.52094938 2.43383482,6.03224157 2.06,9 L2.06,9 Z M17.94,9 C17.5661652,6.03224157 15.5679327,3.52094938 12.76,2.49 C13.5549677,4.56894212 13.9682565,6.77427853 13.98,9 L17.94,9 Z M17.94,11 L13.98,11 C13.9682565,13.2257215 13.5549677,15.4310579 12.76,17.51 C15.5679327,16.4790506 17.5661652,13.9677584 17.94,11 Z M8.02,11 C8.18,14.95 9.25,18 10,18 C10.75,18 11.82,14.95 11.98,11 L8.02,11 L8.02,11 Z M8.02,9 L11.98,9 C11.82,5.05 10.75,2 10,2 C9.25,2 8.18,5.05 8.02,9 L8.02,9 Z M10,20 C4.4771525,20 1.77635684e-15,15.5228475 1.77635684e-15,10 C1.77635684e-15,4.4771525 4.4771525,1.77635684e-15 10,1.77635684e-15 C15.5228475,1.77635684e-15 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z' id='Shape'></path>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent