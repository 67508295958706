import React from 'react'
import styles from './Alert.module.scss'
import attention from '../../assets/images/svg/attention.svg'
import closeIcon from '../../assets/images/svg/close_icon.svg'

export default ({ header, message, closeClick }) => {
  return (
    <div className={styles.modal}>
      <img
        className={styles.close}
        src={closeIcon}
        alt=''
        onClick={closeClick}
      />
      <img className={styles.icon} src={attention} alt='' />
      <h3 className={styles.headerText}>{header}</h3>
      <p className={styles.text}>{message}</p>
    </div>
  )
}
