import React, { Component, Fragment } from 'react'

const cookieData = {
  privacy: '',
  required: {
    required: true,
    links: ['']
  },
  data: {
    required: false,
    links: [`${process.env.PUBLIC_URL}/gdprData.js`]
  }
}

/**
 * component runs in two modes: static and dynamic
 */
class Kokuapp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      once: false
    }
  }

  shouldComponentUpdate () {
    return !this.state.once
  }

  componentDidMount () {
    this.appendScriptsToHead()
    this.setState({ once: true })
  }

  getLanguage () {
    return this.props.language || 'is'
  }

  getFormUrl () {
    return process.env.REACT_APP_FORMS_ENDPOINT
  }

  urlFor (filename) {
    return `${this.getFormUrl()}/dist/${filename}?version=${Date.now()}`
  }

  getScripts () {
    const manifestExtScr = this.urlFor('js/manifest.js')
    const vendorExtScr = this.urlFor('js/vendor.js')
    const formExtScr = this.urlFor('js/kokuapp_onload.js')

    return [
      { url: manifestExtScr },
      { url: vendorExtScr },
      {
        url: formExtScr,
        dataUrl: this.getFormUrl(),
        dataLang: this.getLanguage()
      }
    ]
  }

  appendScriptsToHead () {
    const head = document.querySelector('head')

    this.getScripts()
      .map(this.createScript)
      .forEach(script => {
        head.appendChild(script)
      })
  }

  createScript ({ url, dataUrl, dataLang }) {
    let lf = document.createElement('script')
    lf.type = 'text/javascript'
    lf.async = false
    lf.src = url

    if (dataUrl) {
      lf.dataset.url = dataUrl
    }

    if (dataLang) {
      lf.dataset.lang = dataLang || 'is'
    }

    lf.dataset.cookieversion = '1'
    lf.dataset.cookie = JSON.stringify(cookieData)

    return lf
  }

  renderScripts () {
    return this.getScripts().map(({ url, dataUrl, dataLang }, i) => (
      <CookieScript key={i} url={url} dataUrl={dataUrl} dataLang={dataLang} />
    ))
  }

  render () {
    return (
      <Fragment>
        <link
          rel='stylesheet'
          type='text/css'
          href={this.urlFor('css/kokuapp.css')}
        />
        {this.renderScripts()}
        <div id='form-client-cookies' className='mh-100vh' />
      </Fragment>
    )
  }
}

const CookieScript = ({ url, dataUrl, dataLang }) => (
  <script
    key={url}
    type='text/javascript'
    src={url}
    data-url={dataUrl}
    data-lang={dataLang || 'is'}
    data-cookieversion='1'
    data-cookie={JSON.stringify(cookieData)}
  />
)

export default Kokuapp
