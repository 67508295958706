import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './TextInput.module.scss'
import useLanguage from '../../hooks/useLanguage'
import { DataContext } from '../../contexts/DataContext'

export default ({
  dataKey,
  placeholder,
  validations,
  categoryIndex,
  className,
  errorMessage,
  title,
  setPageError,
  defaultValue = '',
  minValue = false,
  maxValue = false
}) => {
  const { state: dataStore, dispatch } = useContext(DataContext)
  const [hasError, setHasError] = useState(false)
  const [errorType, setErrorType] = useState('')
  const l = useLanguage()

  const currentValue =
    dataStore[categoryIndex] &&
    typeof dataStore[categoryIndex][dataKey] === 'string' &&
    dataStore[categoryIndex][dataKey] > 0
      ? dataStore[categoryIndex][dataKey]
      : null

  useEffect(() => {
    if (
      !dataStore[categoryIndex] ||
      (!dataStore[categoryIndex][dataKey] &&
        dataStore[categoryIndex][dataKey] !== '')
    ) {
      dispatch({
        type: 'update',
        value: defaultValue,
        dataKey,
        categoryIndex
      })
    }
  }, [])

  const errorHandler = (state, errorType) => {
    setHasError(state)
    setPageError(state)

    if (errorType) {
      setErrorType(errorType)
    }
  }

  const handleChange = ({ target: { value } }) => {
    let newState

    if (minValue || minValue === 0) {
      if (value < minValue) {
        newState = minValue + ''
        errorHandler(true, 'min')
      } else {
        newState = value
        errorHandler(false)
      }
    } else {
      newState = value
      errorHandler(false)
    }

    if (maxValue && value > minValue) {
      if (maxValue === 'currYear') {
        let y = new Date()
        let currYear = y.getFullYear()

        if (value > currYear) {
          newState = currYear + ''
          errorHandler(true, 'max')
        } else {
          newState = value
          errorHandler(false)
        }
      } else if (value > maxValue) {
        newState = currentValue + ''
        errorHandler(true, 'max')
      } else {
        newState = value
        errorHandler(false)
      }
    }

    let str = newState.replace(',', '.')

    if (!isNaN(str) && str) {
      dispatch({
        type: 'update',
        value: newState.trim(),
        dataKey,
        categoryIndex
      })
    } else if (str !== '') {
      errorHandler(true, 'general')
    }
  }

  const fieldClass = classNames(styles.field, className, 'mt-4')

  return (
    <div className='col-md-4 col-lg-3 py-2 py-md-3'>
      <div className={fieldClass}>
        {title && <div className={styles.title}>{l(title)}</div>}
        <input
          className={styles.input}
          type='text'
          placeholder={l(placeholder)}
          defaultValue={currentValue}
          onChange={handleChange}
        />
        <img
          src={
            'https://cdn1.iconfinder.com/data/icons/editing/60/cell-2-0-480.png'
          }
          alt=''
          className={styles.label}
        />
      </div>
      {errorMessage && hasError && (
        <p className={styles.error}>{l(errorMessage[errorType])}</p>
      )}
    </div>
  )
}
