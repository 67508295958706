import React from 'react'

/* eslint max-len: ['error', { 'code': 15000 }] */
const SvgComponent = props => (
  <svg
    width='18px'
    height='19px'
    viewBox='0 0 18 19'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g
      id='Kolefni-desktop-hönnun'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='spurning-4-+--'
        transform='translate(-752.000000, -538.000000)'
        fill='#58585B'
        fillRule='nonzero'
      >
        <g id='Group-7' transform='translate(641.000000, 518.000000)'>
          <g id='icon-plus' transform='translate(111.000000, 20.953125)'>
            <path
              d='M16.5,7.5 C17.3284271,7.5 18,8.17157288 18,9 C18,9.82842712 17.3284271,10.5 16.5,10.5 L10.5,10.5 L10.5,16.5 C10.5,17.3284271 9.82842712,18 9,18 C8.17157288,18 7.5,17.3284271 7.5,16.5 L7.5,10.5 L1.5,10.5 C0.671572875,10.5 0,9.82842712 0,9 C0,8.17157288 0.671572875,7.5 1.5,7.5 L7.5,7.5 L7.5,1.5 C7.5,0.671572875 8.17157288,0 9,0 C9.82842712,0 10.5,0.671572875 10.5,1.5 L10.5,7.5 L16.5,7.5 Z'
              id='Path'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
