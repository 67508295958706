export const localeNumber = lang => {
  const localeLang = lang === 'is' ? 'de' : 'en'

  return (val, fractionDigits) => (
    val.toLocaleString(localeLang, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })
  )
}

export const calcUserTotalCarbon = summonStore => {
  return (
    Object.entries(summonStore).reduce(
      (totalCarbon, [categoryKey, categoryResult]) => {
        const useDetailed = categoryKey.endsWith('_detailed')
        // result can have many result keys eg trip/flight lets sum the up and use detailed when able
        return (totalCarbon += Object.keys(categoryResult.data).reduce(
          (totalResultCarbon, resultKey) => {
            if (useDetailed) {
              // count if field has no detailed version e.g. trip_detailed has no detailed version
              if (!categoryResult.data.hasOwnProperty(`${resultKey}_detailed`)) {
                totalResultCarbon += categoryResult.data[resultKey]
              }
            } else {
              // only count key if it does not end with detailed
              if (!resultKey.endsWith('_detailed')) {
                totalResultCarbon += categoryResult.data[resultKey]
              }
            }
            return totalResultCarbon
          },
          0
        ))
      },
      0
    )
  )
}

export const calcTotalCars = userTotalCars => (userTotalCars / 1000 / 2.04)

export const formatUserTotalCarbon = userTotalCarbon => (userTotalCarbon / 1000)
