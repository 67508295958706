import React, { Fragment } from 'react'
import styles from './SocialShare.module.scss'
import TwitterShare from '../../components/TwitterShare'
import FacebookShare from '../../components/FacebookShare'
import ChromeShare from '../../components/ChromeShare'
import DownloadShare from '../../components/DownloadShare'
import classNames from 'classnames'
import translations from '../../data/results'
import useLanguage from '../../hooks/useLanguage'

const SocialShare = (data) => {
  const l = useLanguage()
  const totalCars = data.totalCars
  const totalCarbon = data.formattedUserTotalCarbon

  return (
    <Fragment>
      <div className='row pt-5'>
        <div className='col'>
          <h3
            className={classNames(
              styles.socialHeading,
              'hdln--grey text-center pt-3'
            )}
          >
            {l(translations.shareHeader)}
          </h3>
          <p
            className={classNames(styles.socialText, 'hdln--grey text-center')}
          >
            {l(translations.shareText)}
          </p>
        </div>
      </div>
      <div className='row pb-4'>
        <div className='col'>
          <div className={classNames(styles.socialIcons, 'pt-3')}>
            <FacebookShare
              co2Footprint={totalCarbon}
              numberOfCars={totalCars}
            />
            <TwitterShare
              co2Footprint={totalCarbon}
              numberOfCars={totalCars}
            />
            <DownloadShare
              co2Footprint={totalCarbon}
              numberOfCars={totalCars}
            />
            <ChromeShare
              co2Footprint={totalCarbon}
              numberOfCars={totalCars}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SocialShare
