import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import translations from '../../data/suggestions'
import useLanguage from '../../hooks/useLanguage'
import Main from '../../layouts/Main'
import SuggestionTabs from '../../components/SuggestionTabs'
import LightbulbHeart from '../../assets/images/svg/LightbulbHeart.js'
import styles from './SuggestionsPage.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import SocialShare from '../../components/SocialShare'
import { localeNumber, calcUserTotalCarbon, calcTotalCars, formatUserTotalCarbon } from '../../util'
import { useSummon } from '../../hooks/summon'
import { LanguageContext } from '../../contexts/LanguageContext'

const SuggestionsPage = ({
  match: {
    params: { category = 'trips', heading }
  },
  history
}) => {
  const { state: summonStore } = useSummon()
  const { language } = useContext(LanguageContext)
  const l = useLanguage()

  let userTotalCarbon = calcUserTotalCarbon(summonStore)
  const totalCars = calcTotalCars(userTotalCarbon)
  const formatNumber = localeNumber(language)
  const formattedUserTotalCarbon = formatUserTotalCarbon(userTotalCarbon)
  const total = formatNumber(formattedUserTotalCarbon, 2)

  return (
    <Main>
      <div className='container pb-5'>
        <div className='pt-5'>
          <div className='row'>
            <div className='col d-flex flex-column justify-content-center'>
              <div className='align-self-center pb-4'>
                <LightbulbHeart />
              </div>
              <p className={styles.suggestionHeaderText}>
                {l(translations.title)}
              </p>
            </div>
          </div>
          <SuggestionTabs pageKey={category} history={history} />
          <SocialShare
            formattedUserTotalCarbon={total}
            totalCars={totalCars}
          />
          <div className='row d-flex justify-content-center'>
            <Link
              to='/results'
              className='button--back d-flex justify-content-center'
            >
              <FontAwesomeIcon className='mr-3' icon={faArrowLeft} size='lg' />
              <div>{l(translations.button)}</div>
            </Link>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default SuggestionsPage
