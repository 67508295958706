import React from 'react'

const SvgComponent = props => (
  <svg width='12px' height='9px' viewBox='0 0 12 9' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>Path</title>
    <desc>Created with Sketch.</desc>
    <g id='Kolefni-desktop-hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Artboard' transform='translate(-35.000000, -31.000000)' fill='#6CA234'>
        <path d='M37.3034267,34.1445091 L39.1234267,35.9505091 L43.7434267,31.3305091 C44.3022309,30.8584777 45.1302358,30.8962076 45.6437952,31.4171035 C46.1573546,31.9379994 46.1833365,32.7664562 45.7034267,33.3185091 L40.1034267,38.9185091 C39.5590458,39.4521091 38.6878076,39.4521091 38.1434267,38.9185091 L35.3434267,36.1185091 C34.8635169,35.5664562 34.8894988,34.7379994 35.4030582,34.2171035 C35.9166175,33.6962076 36.7446224,33.6584777 37.3034267,34.1305091 L37.3034267,34.1445091 Z' id='Path'></path>
      </g>
    </g>
  </svg>
)

export default SvgComponent