import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import styles from './IcelandVideo.module.scss'
import videoUrl from '../../assets/video/kolefnis-island.mp4'

class IcelandVideo extends Component {
  render () {
    return (
      <ReactPlayer
        ref={p => {
          this.p = p
        }}
        url={videoUrl}
        playing
        muted
        onEnded={() => this.p.seekTo(4.8)}
        height={640}
        className='d-none d-md-inline'
      />
    )
  }
}

export default IcelandVideo
