import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import ls from '../../data/storage'
import queryString from 'query-string'
import { animateScroll as scroll } from 'react-scroll'
import translations from '../../data/frontpage'
import useLanguage from '../../hooks/useLanguage'
import styles from './FrontPage.module.scss'
import Iceland from '../../assets/images/svg/Iceland'
import FrontPageStats from '../FrontPageStats/FrontPageStats'
import IconNext from '../../assets/images/svg/Icon_arrow_right'
import { DataContext } from '../../contexts/DataContext'
import IcelandVideo from '../IcelandVideo/IcelandVideo'
import Alert from '../../components/Alert'
import downArrow from '../../assets/images/svg/downArrow.svg'

const FrontPage = ({ location: { search }, history }) => {
  const { state, dispatch } = useContext(DataContext)
  const { hasError = false, ...query } = queryString.parse(search)

  // clear state from context
  if (Object.keys(state).length) {
    ls.clear()
    dispatch({
      type: 'clear'
    })
  }

  const l = useLanguage()
  const closeError = () => {
    history.replace({ search: queryString.stringify(query) })
  }

  const scrollDown = () => {
    const scrollDistance = 600
    if (window.scrollY < scrollDistance) {
      scroll.scrollTo(scrollDistance, {
        duration: 450
      })
    }
  }

  return (
    <section className={styles.container}>
      <div
        className={classNames(
          styles.headerWrapper,
          'container pb-3 mb-3 pb-md-2'
        )}
      >
        <div className='row pt-md-6 pt-4'>
          <div className='col-lg-7 col-md-12 d-flex justify-content-center'>
            <div className='d-none d-md-inline'>
              <IcelandVideo />
            </div>
            <div className={classNames(styles.iceland, 'd-inline d-md-none')}>
              <Iceland />
            </div>
          </div>
          <div className='col-lg-5 col-md-12 pt-lg-3 mt-lg-2'>
            <h2 className={classNames('hdln--2 pt-4', styles.headerText)}>
              {l(translations.hero.title)}
            </h2>
            <div>
              <p
                className={styles.textStyle}
                dangerouslySetInnerHTML={{ __html: l(translations.hero.text) }}
              />
            </div>
            <div className='mt-5 d-flex justify-content-center justify-content-md-start'>
              <Link
                to='/steps/1/1'
                className='button--calculate button--pink d-flex justify-content-center'
              >
                <span className='button__text'>
                  {l(translations.hero.button)}
                </span>
                <div className='pl-2'>
                  <IconNext />
                </div>
              </Link>
            </div>
          </div>
          <div className='col-12 d-flex justify-content-center mt-3 mt-md-4 mt-lg-5 pb-2'>
            <img
              onClick={scrollDown}
              className={styles.downArrow}
              src={downArrow}
              alt=''
            />
          </div>
        </div>
      </div>
      <FrontPageStats />
      {hasError && (
        <Alert
          closeClick={closeError}
          header={l(translations.error.header)}
          message={l(translations.error.message)}
        />
      )}
    </section>
  )
}

export default FrontPage
