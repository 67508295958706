import { useContext } from 'react'
import { LanguageContext } from '../contexts/LanguageContext'

const useShareLink = ({ co2Footprint, numberOfCars }) => {
  const { language } = useContext(LanguageContext)
  // create base64 string containing our data
  const data = btoa(`${co2Footprint};${numberOfCars};${language}`)
  return `https://www.kolefnisreiknir.is/minar-nidurstodur?data=${encodeURIComponent(data)}`
}

export default useShareLink
