import React, { useReducer, useContext, createContext } from 'react'
import fetch from 'isomorphic-unfetch'
import ls from '../data/storage'
export const SummonContext = createContext()

const serialize = queryObject => {
  return Object.keys(queryObject)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        queryObject[key]
      )}`
    })
    .join('&')
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      const { key } = action
      return {
        ...state,
        ...{
          [key]: {
            loading: true,
            success: false,
            error: false,
            data: state[key] ? state[key].data : {}
          }
        }
      }
    }
    case 'success': {
      const { key, data } = action
      // we are setting all properties of state, no need to spread state
      return {
        ...state,
        ...{
          [key]: {
            loading: false,
            success: true,
            error: false,
            data
          }
        }
      }
    }
    case 'error': {
      const { key, error } = action
      return {
        ...state,
        ...{
          [key]: {
            loading: false,
            success: false,
            error: error
          }
        }
      }
    }
    case 'clear': {
      const { key } = action
      const {[key]: removedKey, ...newState} = state
      return newState
    }
    case 'reset': {
      return null
    }
    default: {
      console.log(`Summon: Missing action type ${action.type}`)
    }
  }
}

export const SummonProvider = ({ children }) => {
  const defaultState = JSON.parse(ls.get('kolefnis_data'))
  const [state, dispatch] = useReducer(reducer, defaultState || {})
  ls.set('kolefnis_data', JSON.stringify(state))
  const value = { state, dispatch }
  return (
    <SummonContext.Provider value={value}>{children}</SummonContext.Provider>
  )
}

export const useSummon = () => {
  const { state, dispatch } = useContext(SummonContext)

  const summon = options => {
    dispatch({ type: 'init', key: options.key })

    const {
      method = 'GET',
      credentials = 'omit',
      headers = {},
      data = {},
      key,
      url: baseUrl
    } = options
    let url

    let fetchConfig = {
      method,
      credentials,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    }

    if (method.toLowerCase() === 'get') {
      url = `${baseUrl}?${serialize(data)}`
    } else {
      url = baseUrl
      fetchConfig.body = JSON.stringify(data)
    }

    return fetch(url, fetchConfig)
      .then(async response => {
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
      .then(data => {
        dispatch({
          type: 'success',
          key,
          data
        })
      })
      .catch(error => {
        dispatch({
          type: 'error',
          error,
          key
        })

        console.log(`Summon: Error fetching ${key}`, error)
      })
  }

  const unsummon = dataKey => {
    dispatch({
      type: 'clear',
      key: dataKey
    })
  }

  return { state, unsummon, summon }
}
