import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { DataContext } from '../../contexts/DataContext'
import translations from '../../data/steps'
import useLanguage from '../../hooks/useLanguage'
import styles from './MoreOptions.module.scss'
import MoreOptionIcon from '../../assets/images/svg/moreOptionIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

const MoreOptions = ({ children, categoryIndex, onClose }) => {
  const { state: dataStore, dispatch } = useContext(DataContext)
  const l = useLanguage()

  useEffect(() => {
    // init data store key if not in store
    if (
      !dataStore[categoryIndex] ||
      typeof dataStore[categoryIndex]['detailed'] === 'undefined'
    ) {
      dispatch({
        type: 'update',
        value: false,
        dataKey: 'detailed',
        categoryIndex
      })
    }
  }, [])

  const updateDetailedState = detailedBool => {
    if (!detailedBool) {
      onClose()
    }
    dispatch({
      type: 'update',
      value: detailedBool,
      dataKey: 'detailed',
      categoryIndex
    })
  }

  const showMoreOptions = dataStore[categoryIndex]
    ? dataStore[categoryIndex]['detailed']
    : false

  const moreOptionClasses = classNames (
    { [styles.open]: showMoreOptions },
    styles.container,
    'container-fluid',
    styles.containerOptionsButton
  )

  return (
    <section className={moreOptionClasses}>
      <div className='d-flex justify-content-center pt-3'>
        <button
          className={classNames('button', styles.moreOptionsButton)}
          onClick={() => updateDetailedState(!showMoreOptions)}
        >
          <div className='d-flex justify-content-center'>
            <MoreOptionIcon />
            <h2 className={classNames(styles.textStyle, 'ml-2')}>
              {showMoreOptions
                ? l(translations.lessOptions)
                : l(translations.moreOptions)}
            </h2>
            <div className='ml-2'>
              <div className={styles.arrow}>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </div>
        </button>
      </div>
      <div className='row d-flex justify-content-center mx-md-4'>
        {children}
      </div>
    </section>
    )
}

export default MoreOptions
