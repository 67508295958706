import React, { useContext } from 'react'
import classNames from 'classnames'
import Tooltip from 'rc-tooltip'
import translations from '../../data/results'
import useLanguage from '../../hooks/useLanguage'
import { useSummon } from '../../hooks/summon'
import { LanguageContext } from '../../contexts/LanguageContext'
import Main from '../../layouts/Main'
import Stats from '../../components/Stats'
import SocialShare from '../../components/SocialShare'
import Cars from '../../components/Cars'
import Chart from '../../components/Chart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { localeNumber, calcUserTotalCarbon, calcTotalCars, formatUserTotalCarbon } from '../../util'
import styles from './ResultsPage.module.scss'

const chartSectionClasses = classNames(
  styles.chartContainer,
  'container-fluid',
  'mt-5',
  'py-4'
)

const titleClasses = classNames(styles.textStyleBold, 'hdln--3 hdln--grey')

const ResultsPage = () => {
  const { state: summonStore } = useSummon()
  const l = useLanguage()

  let userTotalCarbon = calcUserTotalCarbon(summonStore)

  const { language } = useContext(LanguageContext)
  const totalCars = calcTotalCars(userTotalCarbon)
  const formatNumber = localeNumber(language)
  const formattedUserTotalCarbon = formatUserTotalCarbon(userTotalCarbon)
  const total = formatNumber(formattedUserTotalCarbon, 2)

  const printFormattedCars = totalCars => {
    let carUnit

    if (language === 'is') {
      carUnit = totalCars > 1 ? 'bílum' : 'bíl'
    } else {
      carUnit = totalCars > 1 ? 'cars' : 'car'
    }

    return `${formatNumber(totalCars, 1)} ${carUnit}`
  }

  return (
    <Main>
      <div className='container'>
        <div className='row pt-3 d-flex justify-content-center'>
          <div className='col-12 pt-3 pt-md-5'>
            <div className='d-flex justify-content-center'>
              <h2 className={styles.headerText}>{l(translations.title)}</h2>
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <h2 className={styles.percentageResult}>
                {total}
              </h2>
              <p className={classNames(titleClasses, 'align-self-center')}>
                {l(translations.tooltipResultUnit) + ' ' + l(translations.unit)}
              </p>
            </div>
            <div className='d-flex justify-content-center pt-3 mt-2'>
              <p className={styles.textStyle}>
                {l(translations.result_summary) + ' '}
                <strong className='hdln--3 d-md-inline d-block'>
                  {printFormattedCars(totalCars)}
                  <Tooltip
                    placement='top'
                    trigger='hover'
                    animation='zoom'
                    overlay={
                      <div style={{ maxWidth: '400px' }}>
                        {l(translations.tooltipText)}
                      </div>
                    }
                  >
                    <FontAwesomeIcon
                      className={classNames('ml-1 mb-2', styles.infoIcon)}
                      icon={faInfoCircle}
                    />
                  </Tooltip>
                </strong>
              </p>
            </div>
          </div>
        </div>
        {totalCars > 0 && (
          <div className='row'>
            <div className='col-md-9 offset-md-2 pb-md-5 pb-1'>
              <Cars totalCars={parseFloat(totalCars)} />
            </div>
          </div>
        )}
        <div className='px-lg-6'>
          <Stats
            categories={translations.categories}
            stats={translations.stats}
            currLanguage={language}
          />
        </div>
      </div>
      <SocialShare
        formattedUserTotalCarbon={total}
        totalCars={totalCars}
      />
      <div className={chartSectionClasses}>
        <div className='row'>
          <div className='col text-center'>
            <h2 className={classNames(titleClasses, 'mb-0')}>
              {l(translations.chartHeading)}
            </h2>
            <h3 className='hdln--x-small hdln--grey'>
              {l(translations.chartSubHeading)}
            </h3>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-6 py-3 text-center'>
            {l(translations.chartText)}
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-10 col-lg-6'>
            <Chart />
          </div>
        </div>
        <div className='row'>
          <div className='col-8 offset-2 pt-3 text-center highchart-custom-legend'>
            {l(translations.chartLegend)}
          </div>
        </div>
      </div>
    </Main>
  )
}

export default ResultsPage
