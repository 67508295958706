import React, { useContext, useEffect, useState } from 'react'
import styles from './NumberInput.module.scss'
import useLanguage from '../../hooks/useLanguage'
import { DataContext } from '../../contexts/DataContext'
import Plus from '../../assets/images/svg/Icon_plus'
import Minus from '../../assets/images/svg/Icon_minus'
import classNames from 'classnames'

const NumberInput = ({ values, categoryIndex, className }) => {
  const { state: dataStore, dispatch } = useContext(DataContext)
  const [hasError, setHasError] = useState(false)
  const l = useLanguage()

  useEffect(() => {
    for (const value of values) {
      const dataKey = value.key
      const defaultValue = value.defaultValue || 0

      // init data store key if not in store
      if (
        !dataStore[categoryIndex] ||
        (!dataStore[categoryIndex][dataKey] &&
          dataStore[categoryIndex][dataKey] !== 0)
      ) {
        dispatch({
          type: 'update',
          value: defaultValue,
          dataKey,
          categoryIndex
        })
      }
    }
  }, [])

  const handleChange = (dataKey, updateValue, minValue = false) => {
    let newValue = dataStore[categoryIndex][dataKey] + updateValue
    if (newValue <= 0) {
      newValue = 0
    }

    if (!minValue || newValue >= minValue) {
      setHasError(false)
      dispatch({
        type: 'update',
        value: newValue,
        dataKey,
        categoryIndex
      })
    } else {
      setHasError(true)
    }
  }

  return (
    <div className={classNames('container py-3 mx-3', className)}>
      <div className='row justify-content-center'>
        {Object.keys(values).map((key, index) => {
          const value = values[key]
          const valueKey = value.key
          const valueTitle = value.title
          const valueDesciption = value.description
          const errorMessage = value.errorMessage
          const minValue = value.minValue
          const currentValue =
            dataStore[categoryIndex] && dataStore[categoryIndex][valueKey]

          return (
            <div
              className={classNames(
                styles.numberInputContainer,
                'col-6 col-lg-3 py-3'
              )}
              key={index}
            >
              <p className={styles.pickerTitle} key={index}>
                {l(valueTitle)}
              </p>
              {l(value.description) && (
                <div
                  className={classNames(
                    styles.description,
                    'pb-2 hdln--small hdln--grey text-md-center'
                  )}
                >
                  {l(value.description)}
                </div>
              )}
              <div className='d-flex justify-content-center'>
                <div className={styles.numberPicker}>
                  <div className='py-1 d-flex w-100 justify-content-between align-items-center'>
                    <button
                      className={classNames(styles.buttonArea, 'button')}
                      onClick={handleChange.bind(this, valueKey, -1, minValue)}
                    >
                      <Minus />
                    </button>
                    <span className={classNames('pt-1')} key={index}>
                      {currentValue}
                    </span>
                    <button
                      className={classNames(styles.buttonArea, 'button')}
                      onClick={handleChange.bind(this, valueKey, 1, minValue)}
                    >
                      <Plus />
                    </button>
                  </div>
                </div>
              </div>
              <p className={styles.error}>
                {errorMessage && hasError && l(errorMessage)}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NumberInput
