import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { DataProvider } from './contexts/DataContext'
import { LanguageProvider } from './contexts/LanguageContext'
import { SummonProvider } from './hooks/summon'
import StepsPage from './page-templates/StepsPage'
import Homepage from './page-templates/Homepage'
import Page404 from './page-templates/Page404'
import Page500 from './page-templates/Page500'
import ResultsPage from './page-templates/ResultsPage'
import QAPage from './page-templates/QAPage'
import AboutPage from './page-templates/AboutPage'
import SuggestionsPage from './page-templates/SuggestionsPage'
import ScrollToTop from './components/ScrollToTop'
import Kokuapp from './components/Kokuapp'
import { DataContext } from './contexts/DataContext'
import { get } from 'lodash'

const RouteWrapper = ({ component: Component, ...rest }) => {
  const data = useContext(DataContext)

  return (
    <Route
      {...rest}
      render={props => {
        const lastStep = get(props, 'match.params.category', 0) - 2
        const showComponent =
          lastStep === -1 || typeof data.state[lastStep] !== 'undefined'

        return showComponent ? <Component {...props} /> : <Redirect to='/' />
      }}
    />
  )
}

function App () {
  return (
    <LanguageProvider>
      <SummonProvider>
        <DataProvider>
          <Router>
            <ScrollToTop>
              <Route exact path='/' component={Homepage} />
              <RouteWrapper
                path='/steps/:category?/:step?'
                component={StepsPage}
              />
              <Route path='/results' component={ResultsPage} />
              <Route
                path='/suggestions/:category?'
                component={SuggestionsPage}
              />
              <Route path='/404' component={Page404} />
              <Route path='/500' component={Page500} />
              <Route path='/methodology' component={AboutPage} />
              <Route path='/faq' component={QAPage} />
            </ScrollToTop>
          </Router>
          <Kokuapp />
        </DataProvider>
      </SummonProvider>
    </LanguageProvider>
  )
}

export default App
