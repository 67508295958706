import React, {useContext} from 'react'
import classNames from 'classnames'
import { LanguageContext } from '../../contexts/LanguageContext'
import styles from './Header.module.scss'
import LinkedLogo from '../../components/LinkedLogo'
import Globe from '../../assets/images/svg/Globe'

const Header = () => {
  // language switch
  const { language, setLanguage } = useContext(LanguageContext)
  const newLanguage = language === 'is' ? 'en' : 'is'
  return (
    <div className='container'>
      <div className='row'>
        <div className={classNames(styles.header, 'col d-flex justify-content-center pt-3 mt-2 pt-md-4 mt-md-3')}>
          <div className={styles.logo}>
            <LinkedLogo />
          </div>
          <button className={styles.icon} onClick={() => setLanguage(newLanguage)}>
            <Globe />
            <span className={classNames(styles.text, 'hdln--4 hdln--grey pl-2 text-uppercase')}>{newLanguage}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
