import React from 'react'
import translations from '../../data/shared'
import useLanguage from '../../hooks/useLanguage'
import styles from './SectionFooter.module.scss'
import classNames from 'classnames'
import { HashLink as Link } from 'react-router-hash-link'
import Efla from '../../assets/images/efla_logo.png'

const SectionFooter = ({ categoryIndex }) => {
  const l = useLanguage()
  const footerLinks = classNames(styles.textStyle, styles.underline)

  return (
    <section className={styles.container}>
      <div className='container'>
        <div className='row pt-5 mb-5 d-flex justify-content-center flex-column flex-md-row'>
          <div className='d-flex justify-content-md-end justify-content-center'>
            <div className={styles.or} />
            <img src={Efla} alt='' className={styles.efla} />
          </div>
          <div className={styles.divider} />
          <div className='d-flex flex-wrap justify-content-md-start justify-content-center pt-3 pt-md-0'>
            <Link to='/faq'>
              <p className={footerLinks}>{l(translations.footer.faq)}</p>
            </Link>
            <Link to={`/methodology#category${categoryIndex + 1}`}>
              <p className={footerLinks}>{l(translations.footer.about)}</p>
            </Link>
            <a
              className='external-link'
              href='https://www.or.is/hafa-samband/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <p className={footerLinks}>{l(translations.footer.contact)}</p>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFooter
