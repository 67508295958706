import React from 'react'
import classNames from 'classnames'
import translations from '../../data/QandA'
import useLanguage from '../../hooks/useLanguage'
import Main from '../../layouts/Main'
import styles from './QAPage.module.scss'
import IconBack from '../../assets/images/svg/Icon_arrow_left'

const QAPage = ({ history }) => {
  const l = useLanguage()
  const questions = translations.questions

  return (
    <Main>
      <div className={classNames('container', styles.container)}>
        <div className='row'>
          <div className='col pb-4'>
            <h2 className='hdln--x-large text-center hdln--grey pt-5 mt-2 pb-3 mb-2'>
              {l(translations.hero.title)}
            </h2>
            <div
              className='text-left'
              dangerouslySetInnerHTML={{ __html: l(translations.hero.text) }}
            />
          </div>
        </div>
        {questions &&
          Object.values(questions).map((question, index) => {
            return (
              <div className='row'>
                <div className='col-12 pb-4'>
                  <h4 className='hdln--medium hdln--grey'>
                    {l(question.question)}
                  </h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: l(question.answer) }}
                  />
                </div>
              </div>
            )
          })}
      </div>
      <div className='px-2 px-md-0 pt-4 pb-6 row d-flex justify-content-center'>
        <div className='col-md-6 d-flex justify-content-around'>
          <div
            className='button button--back d-flex justify-content-center align-items-center'
            onClick={history.goBack}
          >
            <IconBack fill='#FFFFFF' />
            <span className='button__text pl-3'>
              {l(translations.buttonBack)}
            </span>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default QAPage
