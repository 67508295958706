import React, { useContext, useEffect } from 'react'
import ReactSlider from 'rc-slider'
import classNames from 'classnames'
import styles from './SliderInput.module.scss'
import useLanguage from '../../hooks/useLanguage'
import { DataContext } from '../../contexts/DataContext'

export default ({ title, sliders, dataKey, categoryIndex, className }) => {
  const { state: dataStore, dispatch } = useContext(DataContext)
  const l = useLanguage()

  const generateReactSliderMarks = values => {
    const stepSize = 100 / (values.length - 1)
    return values.reduce((formatedMarks, value, index) => {
      if (value.title) {
        formatedMarks[stepSize * index] = { label: l(value.title) }
      }
      return formatedMarks
    }, {})
  }

  const getSliderValue = (slider, stepSize, dataStoreValue) => {
    const sliderValueIndex = slider.values.findIndex(
      sliderValue => sliderValue.value === dataStoreValue
    )
    return sliderValueIndex * stepSize
  }

  // TODO: This is not initing
  useEffect(() => {
    for (const slider of sliders) {
      const key = dataKey + slider.dataKeyPostfix

      // init data store key if not in store
      if (typeof dataStore[categoryIndex] === 'undefined' || typeof dataStore[categoryIndex][key] === 'undefined') {
        const defaultValue = slider.defaultValue || slider.values[0].value
        dispatch({
          type: 'update',
          value: defaultValue,
          dataKey: key,
          categoryIndex
        })
      }
    }
  }, [dataKey])

  const handleChange = (dataKeyPostfix, sliderValues, sliderLocation) => {
    const stepSize = 100 / (sliderValues.length - 1)
    const sliderValueIndex = sliderLocation / stepSize

    dispatch({
      type: 'update',
      dataKey: dataKey + dataKeyPostfix,
      value: sliderValues[sliderValueIndex].value,
      categoryIndex
    })
  }

  return (
    <div className={classNames('container', className)}>
      <div className='row d-flex justify-content-center'>
        {l(title) && (
          <div className='col pt-4 pb-2'>
            <h2 className={styles.headerText}>{l(title)}</h2>
          </div>
        )}
      </div>
      <div className='pt-md-4 pb-3 px-3 px-md-0'>
        {sliders.map((slider, index) => {
          const stepSize = 100 / (slider.values.length - 1)
          const reactSliderMarks = generateReactSliderMarks(slider.values)
          // useEffect runs after first render so this might not have inited yet, assume empty string under corrected
          const sliderValue = dataStore[categoryIndex]
            ? dataStore[categoryIndex][dataKey + slider.dataKeyPostfix]
            : ''
          const currentSliderValue = getSliderValue(
            slider,
            stepSize,
            sliderValue
          )

          return (
            <div
              key={slider.dataKeyPostfix}
              className='row d-flex flex-md-row flex-column pt-4 my-4'
            >
              {l(slider.title) && (
                <p
                  className={classNames(
                    'col-md-3 pb-md-3 pt-md-0 text-md-right',
                    styles.textStyle
                  )}
                >
                  {l(slider.title)}
                </p>
              )}
              <ReactSlider
                className={classNames('col-10 offset-1 offset-md-0', {
                  'col-md-8 offset-md-2': !l(slider.title),
                  'col-md-7 mx-3 ml-md-2': l(slider.title)
                })}
                defaultValue={currentSliderValue}
                onChange={handleChange.bind(
                  this,
                  slider.dataKeyPostfix,
                  slider.values
                )}
                value={currentSliderValue}
                step={stepSize}
                dots
                marks={reactSliderMarks}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
