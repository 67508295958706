import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ls from '../../data/storage'
import translations from '../../data/steps'
import { useSummon } from '../../hooks/summon'
import useLanguage from '../../hooks/useLanguage'
import Main from '../../layouts/Main'
import Steps from '../../components/Steps'
import styles from './StepsPage.module.scss'
import MoreOptions from '../../components/MoreOptions/MoreOptions'
import TextInput from '../../components/TextInput'
import RadioInput from '../../components/RadioInput'
import NumberInput from '../../components/NumberInput'
import SliderInput from '../../components/SliderInput'
import questions from '../../data/questions'
import { DataContext } from '../../contexts/DataContext'
import IconNext from '../../assets/images/svg/Icon_arrow_right'
import IconBack from '../../assets/images/svg/Icon_arrow_left'
import classNames from 'classnames'

const getUrlFriendlyIndex = index => {
  return index + 1
}

const getNext = (categoryIndex, stepIndex) => {
  const nextStep = stepIndex + 1
  const nextCategory = categoryIndex + 1
  const hasNextStep = !!questions[categoryIndex].question_steps[nextStep]
  const isLastCategory = !questions[nextCategory]
  if (hasNextStep) {
    // we have another step
    const urlCategoryIndex = getUrlFriendlyIndex(categoryIndex)
    const urlStepIndex = getUrlFriendlyIndex(nextStep)
    return `/steps/${urlCategoryIndex}/${urlStepIndex}`
  } else if (!isLastCategory) {
    // we have another category
    const urlCategoryIndex = getUrlFriendlyIndex(nextCategory)
    return `/steps/${urlCategoryIndex}/1`
  } else {
    // no other step or category return results link
    return '/results'
  }
}

export const StepsPage = ({
  match: {
    params: { category, step }
  },
  history
}) => {
  const { state: summonStore, unsummon, summon } = useSummon()
  const [pageHasError, setPageHasError] = useState({})
  const l = useLanguage()
  const { state: dataStore } = useContext(DataContext)
  const categoryIndex = category - 1
  const stepIndex = step - 1
  const currentStep = questions[categoryIndex].question_steps[stepIndex]
  const title = l(currentStep.title)
  const description = l(currentStep.description)
  const basicInputs = currentStep.basic_inputs
  const detailedInputs = currentStep.detailed_inputs
  const detailedQuestion = l(currentStep.detailed_question)
  const nextStepPath = getNext(categoryIndex, stepIndex)
  const currStepPath = getNext(categoryIndex, stepIndex - 1)

  const hasDetailedInput = !!Object.keys(detailedInputs).length
  const { REACT_APP_API_ENDPOINT } = process.env
  const components = {
    TextInput,
    RadioInput,
    NumberInput,
    SliderInput
  }

  const checkCanNavigate = e => {
    if (checkPageHasError()) {
      e.preventDefault()
    }
  }

  // if we go to next category summon results for previous category
  const summonCategoryResults = categoryIndexToSend => {
    // redirect to frontpage asap
    if (summonHasError()) {
      // clear data and navigate to prevent errors
      ls.clear()

      // something went very wrong, reload app
      window.location.href = '/?hasError=1'
    } else {
      const category = questions[categoryIndexToSend]
      const {
        endpointPath,
        result_key: resultKey
      } = category.calculation_endpoint
      const dataToSend = { ...dataStore[categoryIndexToSend] }
      const key = resultKey + (dataToSend['detailed'] ? '_detailed' : '')
      delete dataToSend['detailed']

      // clear data if already set
      if(summonStore[resultKey]) {
        unsummon(resultKey)
      }
      if(summonStore[`${resultKey}_detailed`]) {
        unsummon(`${resultKey}_detailed`)
      }

      return summon({
        method: 'POST',
        data: dataToSend,
        url: REACT_APP_API_ENDPOINT + endpointPath,
        key
      })
    }
  }

  const summonHasError = () =>
    Object.entries(summonStore).find(([, result]) => !!result.error)

  const handleNavigateToResults = (resultsPath, event) => {
    event.preventDefault()
    summonCategoryResults(categoryIndex).then(() => {
      if (summonHasError()) {
        // clear data and navigate to prevent errors
        ls.clear()

        // something went very wrong, reload app
        window.location.href = '/?hasError=1'
      } else {
        history.push('/results')
      }
    })
  }

  useEffect(() => {
    const previousCategoryIndex = categoryIndex - 1
    // get results if we go to another category
    if (previousCategoryIndex >= 0) {
      summonCategoryResults(previousCategoryIndex)
    }
  }, [categoryIndex])

  const checkPageHasError = () => {
    for (let error of Object.values(pageHasError)) {
      if (error) {
        return true
      }
    }
    return false
  }

  const inputSetError = (key, error) => {
    setPageHasError({ ...pageHasError, [key]: error })
  }

  const resetPageErrors = () => {
    setPageHasError({})
  }

  const goBack = () => {
    resetPageErrors()
    history.goBack()
  }

  const basicInputContainerStyles =
    dataStore[categoryIndex] &&
    dataStore[categoryIndex].detailed &&
    hasDetailedInput
      ? styles.disabledInput
      : styles.active

  const buttonClasses = checkPageHasError()
    ? classNames(
      'button--navigate button--pink d-flex justify-content-center order-lg-1',
      styles.disabled
    )
    : 'button--navigate button--pink d-flex justify-content-center order-lg-1'

  return (
    <Main categoryIndex={categoryIndex}>
      <div className='container'>
        <div className='row mt-5 pt-1 pt-md-0 mt-md-6 pb-4 mb-2 mb-md-0 pb-md-5 d-flex justify-content-center'>
          <div className='col-10 col-lg-12 d-flex justify-content-center'>
            <Steps
              questions={questions}
              categoryIndex={categoryIndex}
              stepIndex={stepIndex}
            />
          </div>
        </div>
      </div>
      <div className='container overflow-hidden' >
        <div className='row pt-3 d-flex justify-content-center'>
          <div className='col-12 px-3'>
            <h2 className={styles.headerText}>{title}</h2>
            <p className={styles.textStyle}>{description}</p>
          </div>
          {Object.keys(basicInputs).map(inputKey => {
            const InputComponent = components[basicInputs[inputKey].component]
            const options = basicInputs[inputKey].options
            return (
              <InputComponent
                className={basicInputContainerStyles}
                categoryIndex={categoryIndex}
                key={inputKey}
                dataKey={inputKey}
                setPageError={inputSetError.bind(this, inputKey)}
                {...options}
              />
            )
          })}
        </div>
      </div>
      {hasDetailedInput && (
        <MoreOptions categoryIndex={categoryIndex} onClose={resetPageErrors}>
          {detailedQuestion && (
            <div className='col-12'>
              <h2 className={classNames(styles.headerText, 'pt-3 mt-2')}>
                {detailedQuestion}
              </h2>
            </div>
          )}
          {Object.keys(detailedInputs).map(inputKey => {
            const InputComponent =
              components[detailedInputs[inputKey].component]
            const options = detailedInputs[inputKey].options
            return (
              <InputComponent
                categoryIndex={categoryIndex}
                key={inputKey}
                dataKey={inputKey}
                setPageError={inputSetError.bind(this, inputKey)}
                {...options}
              />
            )
          })}
        </MoreOptions>
      )}
      <div className='container'>
        <div className='row px-3 px-md-0 pt-4 pb-4 mb-3 mb-md-0 pb-md-6 d-flex justify-content-center'>
          {nextStepPath !== '/results' && (
            <Link
              className={buttonClasses}
              to={nextStepPath}
              onClick={checkCanNavigate}
              disabled={pageHasError}
            >
              <span className='button__text mr-3'>
                {l(translations.buttonNext)}
              </span>
              <IconNext />
            </Link>
          )}
          {nextStepPath === '/results' && (
            <Link
              to={nextStepPath}
              onClick={handleNavigateToResults.bind(this, nextStepPath)}
              className='button--calculate button--pink d-flex justify-content-center order-lg-1'
            >
              <span className='button__text mr-3'>
                {l(translations.buttonResults)}
              </span>
              <IconNext />
            </Link>
          )}
          {currStepPath !== '/steps/1/1' && (
            <div
              className='button--navigate button--white d-flex justify-content-center align-items-center mt-3 mt-lg-0'
              onClick={goBack}
            >
              <IconBack fill='#58585B' />
              <span className='button__text pl-3'>
                {l(translations.buttonBack)}
              </span>
            </div>
          )}
        </div>
      </div>
    </Main>
  )
}

// TODO: Display dagta on results page

export default StepsPage
