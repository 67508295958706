import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import '../../styles/index.scss'
import styles from './Main.module.scss'
import Header from '../../components/Header'
import SectionFooter from '../../components/SectionFooter'

class MainLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isMounted: false
    }

    this.handleOnScroll = this.handleOnScroll.bind(this)
    this.isMobile = this.isMobile.bind(this)
  }

  componentDidMount () {
    this.setState({ isMounted: true })
    window.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleOnScroll)
  }

  isMobile () {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    const isMobile = width < 400
    return isMobile
  }

  handleOnScroll () {
    const minScrollDist = 70
    const currentScrollTop = -document.body.getBoundingClientRect().top
    const hasScrolledFarEnough =
      Math.abs(this.state.lastScroll - currentScrollTop) > minScrollDist

    if (currentScrollTop > 36 && hasScrolledFarEnough) {
      this.setState({
        isScrolled: true,
        scrollTop: false,
        lastScroll: currentScrollTop
      })
    } else if (currentScrollTop <= 36 && this.state.isScrolled) {
      this.setState({ isScrolled: false, scrollTop: true })
    }
  }

  render () {
    const { categoryIndex } = this.props
    return (
      <Fragment>
        <Helmet>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>Kolefnisreiknir</title>
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href='/apple-touch-icon.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/favicon-32x32.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/favicon-16x16.png'
          />
          <link rel='manifest' href='/site.webmanifest' />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
          <meta name='msapplication-TileColor' content='#da532c' />
          <meta name='theme-color' content='#ffffff' />
          <html lang='is' />
        </Helmet>

        <nav className='sr-only' aria-label='skip to main content'>
          <a href='#main' className='sr-only'>
            Skip to main content
          </a>
        </nav>

        <main id='main' className={styles.maxWidth}>
          <Header />
          {this.props.children}
          <SectionFooter categoryIndex={categoryIndex} />
        </main>
      </Fragment>
    )
  }
}

export default MainLayout
