import React from 'react'

const SvgComponent = props => (
  <svg width='70px' height='97px' viewBox='0 0 70 97'>
      {/* <!-- Generator: sketchtool 56.3 (101010) - https://sketch.com --> */}
    <title>4C7C2656-7933-4AD1-88E1-F21119F0B027</title>
    <desc>Created with sketchtool.</desc>
    <g id='Kolefni-mobile-hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Niðurstöður-detail' transform='translate(-153.000000, -368.000000)'>
        <g id='lighbulbheart.3' transform='translate(153.000000, 368.000000)'>
          <path d='M41.998625,21.6666667 C39.741125,21.6666667 37.5942917,22.5211667 35.9537917,24.0738333 C35.309125,24.6826667 34.3033333,24.6826667 33.662125,24.0738333 C32.023125,22.5211667 29.8779583,21.6666667 27.6187917,21.6666667 C21.621125,21.6666667 18.828125,24.4596667 18.828125,30.4573333 C18.828125,36.7626667 31.8439583,47.8288333 34.798125,49.3636667 C37.7994583,47.793 50.7892917,36.8295 50.7892917,30.4573333 C50.7892917,24.4596667 47.9964583,21.6666667 41.998625,21.6666667 Z' id='Path' fill='#0CC0D8' opacity='0.200000003' />
          <path d='M35,93.3333333 C38.100625,93.3333333 40.7144583,91.206 41.455,88.3333333 L28.545,88.3333333 C29.2854583,91.206 31.8992917,93.3333333 35,93.3333333 Z' id='Path' />
          <path d='M35,3.33333333 C17.5389583,3.33333333 3.33333333,17.539 3.33333333,35 C3.33333333,47.5943333 10.7909583,58.9893333 22.3339583,64.0331667 C22.9409583,64.2985417 23.3333333,64.8975 23.3333333,65.5598333 L23.3333333,75 L46.6666667,75 L46.6666667,65.5598333 C46.6666667,64.8975 47.0589583,64.2985 47.6659583,64.0331667 C59.2089583,58.9893333 66.6666667,47.5943333 66.6666667,35 C66.6666667,17.539 52.4609583,3.33333333 35,3.33333333 Z M43.2567917,46.9645 C41.804625,48.2813333 38.8567917,50.8446667 36.6666667,52.0806667 L36.6666667,66.6666667 L33.3333333,66.6666667 L33.3333333,52.293 C31.069125,51.1625 27.7737917,48.292 26.2044583,46.8603333 C22.2052917,43.2113333 15.4947917,36.2271667 15.4947917,30.4573333 C15.4947917,22.6383333 19.7997917,18.3333333 27.6187917,18.3333333 C30.231125,18.3333333 32.7342917,19.1633333 34.8079583,20.6933333 C36.883125,19.1633333 39.3862917,18.3333333 41.998625,18.3333333 C49.817625,18.3333333 54.122625,22.6383333 54.122625,30.4573333 C54.122625,36.25 47.3144583,43.2861667 43.2567917,46.9645 Z' id='Shape' />
          <path d='M35,0 C15.7014583,0 0,15.7015 0,35 C0,48.5515 7.814125,60.8496667 20,66.6325 L20,76.6666667 C20,77.5878333 20.7454583,78.3333333 21.6666667,78.3333333 L48.3333333,78.3333333 C49.2544583,78.3333333 50,77.5878333 50,76.6666667 L50,66.6325 C62.1857917,60.8496667 70,48.5515 70,35 C70,15.7015 54.2984583,0 35,0 Z M47.6659583,64.0331667 C47.0589583,64.2985 46.6666667,64.8975 46.6666667,65.5598333 L46.6666667,75 L23.3333333,75 L23.3333333,65.5598333 C23.3333333,64.8975 22.9409583,64.2985 22.3339583,64.0331667 C10.7909583,58.9893333 3.33333333,47.5943333 3.33333333,35 C3.33333333,17.539 17.5389583,3.33333333 35,3.33333333 C52.4609583,3.33333333 66.6666667,17.539 66.6666667,35 C66.6666667,47.5943333 59.2089583,58.9893333 47.6659583,64.0331667 Z' id='Shape' fill='#58585B' fillRule='nonzero' />
          <rect id='Rectangle' fill='#58585B' fillRule='nonzero' x='21.6666667' y='80' width='26.6666667' height='3.33333333'></rect>
          <path d='M43.3333333,85 L26.6666667,85 C25.7454583,85 25,85.7455 25,86.6666667 C25,92.181 29.485625,96.6666667 35,96.6666667 C40.5142917,96.6666667 45,92.181 45,86.6666667 C45,85.7455 44.2544583,85 43.3333333,85 Z M35,93.3333333 C31.8992917,93.3333333 29.2854583,91.206 28.545,88.3333333 L41.455,88.3333333 C40.7144583,91.206 38.100625,93.3333333 35,93.3333333 Z' id='Shape' fill='#58585B' fillRule='nonzero' />
          <path d='M41.998625,18.3333333 C39.3862917,18.3333333 36.883125,19.1633333 34.8079583,20.6933333 C32.7342917,19.1633333 30.231125,18.3333333 27.6187917,18.3333333 C19.7997917,18.3333333 15.4947917,22.6383333 15.4947917,30.4573333 C15.4947917,36.2271667 22.2052917,43.2113333 26.2044583,46.8603333 C27.7737917,48.292 31.069125,51.1625 33.3333333,52.293 L33.3333333,66.6666667 L36.6666667,66.6666667 L36.6666667,52.0806667 C38.8567917,50.8446667 41.804625,48.2813333 43.2567917,46.9645 C47.3144583,43.2861667 54.122625,36.25 54.122625,30.4573333 C54.122625,22.6383333 49.817625,18.3333333 41.998625,18.3333333 Z M34.798125,49.3636667 C31.8439583,47.8288333 18.828125,36.7626667 18.828125,30.4573333 C18.828125,24.4596667 21.621125,21.6666667 27.6187917,21.6666667 C29.8779583,21.6666667 32.023125,22.5211667 33.662125,24.0738333 C34.3033333,24.6826667 35.309125,24.6826667 35.9537917,24.0738333 C37.5942917,22.5211667 39.741125,21.6666667 41.998625,21.6666667 C47.9964583,21.6666667 50.7892917,24.4596667 50.7892917,30.4573333 C50.7892917,36.8295 37.7994583,47.793 34.798125,49.3636667 Z' id='Shape' fill='#58585B' fillRule='nonzero' />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
