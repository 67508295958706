import React from 'react'

/* eslint max-len: ['error', { 'code': 15000 }] */
const SvgComponent = props => (
<svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="wizard-/-step2-copy" transform="translate(-22.000000, -83.000000)" fill="#FFFFFF" fillRule="nonzero">
            <g id="icon-check-circle" transform="translate(22.000000, 83.000000)">
                <path d="M3.93833333,4.695 L6.51666667,7.2535 L13.0616667,0.7085 C13.853306,0.0397889103 15.0263129,0.0932395195 15.7538554,0.831175431 C16.4813978,1.56911134 16.5182055,2.74275838 15.8383333,3.52483333 L7.905,11.4581667 C7.1337937,12.2141 5.89953963,12.2141 5.12833333,11.4581667 L1.16166667,7.4915 C0.481794482,6.70942504 0.518602156,5.53577801 1.2461446,4.7978421 C1.97368705,4.05990619 3.14669396,4.00645558 3.93833333,4.67516667 L3.93833333,4.695 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
)

export default SvgComponent