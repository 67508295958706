import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'
import download from '../../assets/images/svg/download.svg'

const DownloadShare = ({ co2Footprint, numberOfCars }) => {
  const { language } = useContext(LanguageContext)
  const link = `/mynd/${language}/instastory.png?co2Footprint=${co2Footprint}&numberOfCars=${numberOfCars}`
  return (
    <a href={link} download>
      <img alt='Download results' src={download} />
    </a>
  )
}

export default DownloadShare
