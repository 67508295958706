import React from 'react'

/* eslint max-len: ['error', { 'code': 15000 }] */
const SvgComponent = props => (
<svg width="20px" height="17px" viewBox="0 0 20 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Kolefni-desktop-hönnun" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="spurning-2-slider-Expand" transform="translate(-790.000000, -1734.000000)" fill="#FFFFFF" fillRule="nonzero">
            <g id="Group-5" transform="translate(601.000000, 1718.000000)">
                <g id="icon-arrow-right" transform="translate(189.000000, 16.000000)">
                    <path d="M16.59,10 L1,10 C0.44771525,10 0,9.55228475 0,9 C0,8.44771525 0.44771525,8 1,8 L16.59,8 L11.29,2.7 C11.0065035,2.45355125 10.8850342,2.06901456 10.9755153,1.70443202 C11.0659964,1.33984947 11.3531635,1.05672702 11.7189927,0.971426147 C12.0848219,0.886125278 12.4675971,1.01303645 12.71,1.3 L19.71,8.3 C20.0911429,8.68884351 20.0911429,9.31115649 19.71,9.7 L12.71,16.7 C12.3156765,17.0427927 11.7239217,17.0242342 11.3518532,16.6574061 C10.9797846,16.290578 10.9528347,15.6991459 11.29,15.3 L16.59,10 L16.59,10 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default SvgComponent