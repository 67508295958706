import React, { useContext, useEffect } from 'react'
import styles from './RadioInput.module.scss'
import classNames from 'classnames'
import { DataContext } from '../../contexts/DataContext'
import useLanguage from '../../hooks/useLanguage'
import CheckMark from '../../assets/images/svg/checkmark.js'

export default ({
  dataKey,
  values,
  title,
  subTitle,
  categoryIndex,
  className,
  defaultValue
}) => {
  const { state: dataStore, dispatch } = useContext(DataContext)
  const radioClasses = classNames(
    'col-md-3 pb-3 my-md-3 mx-3 mx-md-0',
    'd-flex flex-row flex-md-column-reverse justify-content-md-center'
  )
  const l = useLanguage()

  useEffect(() => {
    if (!dataStore[categoryIndex] || !dataStore[categoryIndex][dataKey]) {
      const value = defaultValue || values[0].value

      dispatch({
        type: 'update',
        value,
        dataKey,
        categoryIndex
      })
    }
  }, [])

  const handleChange = value => {
    dispatch({
      type: 'update',
      value,
      dataKey,
      categoryIndex
    })
  }

  return (
    <section className={classNames(styles.container, 'container', className)}>
      {title && (
        <div className='row d-flex justify-content-center pt-3 pb-2'>
          <div className='col-12'>
            <h2 className={styles.headerText}>{l(title)}</h2>
          </div>
          {subTitle && (
            <div className='col-12 text-center pb-3'>
              <div className={styles.subHeading}>{l(subTitle)}</div>
            </div>
          )}
        </div>
      )}
      <div className='row pt-1 pb-3 d-flex justify-content-md-center'>
        {values &&
          values.map((value, index) => {
            const isSelected =
              dataStore[categoryIndex] &&
              value.value === dataStore[categoryIndex][dataKey]

            return (
              <div
                key={index} className={radioClasses}
                onClick={handleChange.bind(this, value.value, index)}
              >
                <div className='d-flex justify-content-md-center mt-md-2'>
                  <div
                    className={classNames(
                      'button',
                      isSelected
                        ? styles.radioButtonSelected
                        : styles.radioButton
                    )}
                  >
                    {isSelected && <CheckMark />}
                  </div>
                </div>
                <div className='pl-3 pl-md-0'>
                  <p className='text-left text-md-center text-break'>
                    {l(value.title)}
                  </p>
                  {l(value.description) && (
                    <div
                      className={classNames(
                        styles.description,
                        'pb-2 hdln--small hdln--grey text-md-center'
                      )}
                    >
                      {l(value.description)}
                    </div>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </section>
  )
}
