import React, { useReducer, createContext } from 'react'
import ls from '../data/storage'
export const DataContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'update': {
      // we dont have to think about steps due to the keys being unique
      // we can find what keys to submit per step due to json
      // this allows us to simplify the store logic
      const categoryUpdate = {
        ...{
          [action.categoryIndex]: {
            ...state[action.categoryIndex],
            ...{ [action.dataKey]: action.value }
          }
        }
      }

      return { ...state, ...categoryUpdate }
    }
    case 'clear': {
      return {}
    }
    default: {
      console.log('DataContext missing type', action.type)
      return state
    }
  }
}

export const DataProvider = ({ children }) => {
  const defaultState = JSON.parse(ls.get('kolefnis_input'))
  const [state, dispatch] = useReducer(reducer, defaultState || {})
  ls.set('kolefnis_input', JSON.stringify(state))
  const value = { state, dispatch }
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
