import React from 'react'
import classNames from 'classnames'
import { SummonContext } from '../../hooks/summon'
import ReactResizeDetector from 'react-resize-detector'
import Tooltip from 'rc-tooltip'
import useLanguage from '../../hooks/useLanguage'
import { Link } from 'react-router-dom'
import styles from './Stats.module.scss'
import translate from '../../data/results'
import pointLeft from '../../assets/images/svg/pointLeft.svg'
import lightbulbHeart from '../../assets/images/svg/lightbulbHeart.svg'
import { localeNumber } from '../../util'

class Stats extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      tooltipToggleState: {},
      statsContainerHeight: 0
    }

    this.onTooltipToggle = this.onTooltipToggle.bind(this)
    this.onResize = this.onResize.bind(this)
    this.calculateUserStats = this.calculateUserStats.bind(this)
  }

  onTooltipToggle (visible, key) {
    this.setState({
      tooltipToggleState: {
        ...this.state.tooltipToggleState,
        [key]: visible
      }
    })
  }

  getUserData (dataStore, key, useDetailed) {
    if (useDetailed) {
      return dataStore[`${key}_detailed`].data
    } else if (dataStore[key]) {
      return dataStore[key].data
    } else {
      return 0
    }
  }

  calculateUserStats (userResults, categoryPremises) {
    return Object.keys(categoryPremises).reduce((groupedStats, statsKey) => {
      const useDetailed = userResults.hasOwnProperty(`${statsKey}_detailed`)
      const data = this.getUserData(userResults, statsKey, useDetailed)

      const {
        europeanTotalCarbon,
        icelanderTotalCarbon,
        title
      } = categoryPremises[statsKey]

      // sum upp all values in category
      const dataSum = Object.keys(data).reduce((sum, resultKey) => {
        if (useDetailed) {
          // count if field has no detailed version e.g. trip_detailed has no detailed version
          if (!data.hasOwnProperty(`${resultKey}_detailed`)) {
            sum += data[resultKey]
          }
        } else {
          // only cound key if it does not end with detailed
          if (!resultKey.endsWith('_detailed')) {
            sum += data[resultKey]
          }
        }

        return sum
      }, 0)

      groupedStats[statsKey] = {
        userTotalCarbon: dataSum,
        title,
        icelanderTotalCarbon,
        europeanTotalCarbon
      }

      return groupedStats
    }, {})
  }

  onResize = (width, height) => {
    this.setState({ statsContainerHeight: height })
  }

  render () {
    const { tooltipToggleState, statsContainerHeight } = this.state
    const calculatedStats = this.calculateUserStats(
      this.context.state,
      this.props.stats
    )

    const {
      isFrontpage,
      categories,
      language: l,
      currLanguage,
      activeCategory = false
    } = this.props
    const hasManyColumns = categories.length > 2
    const pixlePerPrecent = statsContainerHeight / 100
    const displaySingleColumn = !!activeCategory

    const formatNumber = localeNumber(currLanguage)

    // find the highest column
    const highestColumn =
      Math.max(
        ...Object.values(calculatedStats).reduce((numbers, stats) => {
          // create a new object so we dont remove the title
          const newStats = { ...stats }
          delete newStats.title
          // comine all the numbers and return an array
          return [...numbers, ...Object.values(newStats)]
        }, [])
      ) || 5000

    let displayColumns
    if (displaySingleColumn) {
      displayColumns = { [activeCategory]: calculatedStats[activeCategory] }
    } else {
      displayColumns = { ...calculatedStats }
    }

    return (
      <section className={styles.container}>
        {!isFrontpage && !displaySingleColumn && (
          <div
            className={classNames(
              styles.instructionsContainer,
              'd-flex justify-content-center flex-row-reverse flex-xl-row pb-5'
            )}
          >
            <img className={styles.pointLeft} src={pointLeft} alt='' />
            <div className='d-flex flex-xl-column text-center'>
              <img className={styles.bulb} src={lightbulbHeart} alt='' />
              <p
                className={classNames(
                  styles.instructions,
                  'hdln--grey text-center hdln--medium mb-0'
                )}
              >
                {l(translate.tipPointerText)}
              </p>
            </div>
          </div>
        )}
        <div
          className={classNames(
            styles.columnContainer,
            displaySingleColumn && styles.short,
            'row px-3 px-md-0 justify-content-center'
          )}
        >
          <ReactResizeDetector
            handleHeight
            handleWidth
            onResize={this.onResize}
          />

          {Object.keys(displayColumns).map((statsKey, index) => {
            const {
              europeanTotalCarbon,
              icelanderTotalCarbon,
              userTotalCarbon = false,
              title
            } = calculatedStats[statsKey]

            const columnProperties = [
              {
                total: europeanTotalCarbon,
                type: 'european',
                title
              },
              {
                total: icelanderTotalCarbon,
                type: 'icelander',
                title
              }
            ]

            // we have no user data on front page so hide the column
            if (!isFrontpage) {
              columnProperties.push({
                total: userTotalCarbon,
                type: 'user',
                title
              })
            }

            // lets scale the graph according to our display mode
            let graphMaxHeight
            if (displaySingleColumn) {
              graphMaxHeight = Math.max(
                europeanTotalCarbon,
                icelanderTotalCarbon,
                userTotalCarbon
              )
            } else {
              graphMaxHeight = highestColumn
            }

            // we ajust the graph width according to display mode
            const wrapperClasses = classNames(
              'd-flex justify-content-center',
              { 'col-3': !displaySingleColumn },
              { 'col-12': displaySingleColumn }
            )
            return (
              <div className={wrapperClasses} key={index}>
                {columnProperties.map((data, index) => {
                  const height =
                    (data.total / graphMaxHeight) * 100 * pixlePerPrecent
                  let columnClasses
                  switch (data.type) {
                    case 'user': {
                      columnClasses = 'statsIndividual'
                      break
                    }
                    case 'icelander': {
                      columnClasses = classNames(
                        'statsIceland',
                        { 'grey-border': isFrontpage },
                        { IsResults: hasManyColumns }
                      )
                      break
                    }
                    case 'european': {
                      columnClasses = classNames('statsEurope', {
                        EuResults: hasManyColumns
                      })
                      break
                    }
                  }

                  const tooltipText =
                    `${formatNumber(data.total / 1000, 2)} ${l(translate.tooltipResultUnit)}`

                  const Column = (
                    <Tooltip
                      placement='topRight'
                      trigger='hover'
                      overlay={
                        <div className={styles.tooltipText}>
                          {tooltipText}
                        </div>
                      }
                    >
                      <div className={columnClasses} style={{ height }}>
                        <div className='mobile-tooltip'>
                          <div className='mobile-tooltip__result'>
                            {formatNumber(data.total / 1000, 2) + 't'}
                          </div>
                          <div className='mobile-tooltip__unit'>CO₂</div>
                        </div>
                      </div>
                    </Tooltip>
                  )

                  return (
                    <div
                      className='d-flex justify-content-center align-items-end'
                      key={index}
                    >
                      {isFrontpage && Column}
                      {!isFrontpage && (
                        <Link
                          to={'/suggestions/' + statsKey}
                          state={l(data.title)}
                        >
                          {Column}
                        </Link>
                      )}
                    </div>
                  )
                })}
                <Link
                  to={'/suggestions/' + statsKey}
                  state={l(calculatedStats[statsKey].title)}
                >
                  <p
                    className={classNames(styles.statsTitle, {
                      'ml-3': !hasManyColumns
                    })}
                  >
                    {l(calculatedStats[statsKey].title)}
                  </p>
                </Link>
              </div>
            )
          })}
        </div>
        {!displaySingleColumn && (
          <div className='d-flex flex-column flex-md-row pt-4'>
            {categories.map((item, key) => {
              return (
                <div className='d-flex px-3' key={key}>
                  <div
                    className={styles.square}
                    style={{ backgroundColor: item.color }}
                  />
                  <p className={styles.categoryTitle}>{l(item.title)}</p>
                </div>
              )
            })}
          </div>
        )}
      </section>
    )
  }
}

Stats.contextType = SummonContext

export default props => {
  const l = useLanguage()
  return <Stats language={l} {...props} />
}
